import { ClientFormUserRole, ClientFormUserRoleValues } from './ClientFormUserRoles';
import { ClientTagType } from './ClientTag';
import { Translations } from './Translation';
import User, { MinimalUser } from './User';

export interface ClientFormRoleRequiredness {
  role: ClientFormUserRoleValues;
  requiresAction?: boolean | null;
}

export interface ClientFormUser extends MinimalUser {
  role: ClientFormUserRoleValues;
  requiresAction?: boolean | null;
  sortOrder?: number | null;
  statusUtc?: string | null;
  acceptedStatus?: boolean;
  formSectionId?: string | null;
  addedAsIndividual?: boolean;
  tagIds?: string[];
  userId?: string;

  user?: MinimalUser; // Mapped onto this object
  otherRoles?: ClientFormRoleRequiredness[]; // FE ONLY
}
export type ClientFormUserOnly = Omit<ClientFormUser, 'role' | 'id' | 'requiresAction'> &
  Partial<Pick<ClientFormUser, 'role' | 'id' | 'requiresAction'>>;

export type ClientFormTag = {
  id: string;
  type: ClientTagType;
  synchronized: boolean;
  translations: Translations<'name'>;
  formSectionId: string | null;

  // Set by FE
  role?: ClientFormUserRoleValues;
  users?: User[];
};

export type ClientFormUserRequest = {
  userId: string;
  addedAsIndividual: boolean;
  requiresAction: boolean | null;
  sortOrder: number | null;
  tagIds: string[];
  formSectionId?: string | null;

  user?: User; // Mapped onto this object
  role?: ClientFormUserRoleValues; // Mapped onto this object
};

export const clientFormUsersToRequestDto = (users: (ClientFormUser | ClientFormUserRequest)[], tags: ClientFormTag[]): ClientFormUserDto[] => {
  return Object.values(ClientFormUserRole).map((role) => ({
    role,
    users: users
      .filter((user) => user.role === role)
      .map((user) => ({
        userId: 'userId' in user ? (user.userId ?? '') : user.id,
        addedAsIndividual: user.addedAsIndividual ?? false,
        requiresAction: user.requiresAction ?? null,
        sortOrder: user.sortOrder ?? null,
        tagIds: user.tagIds || [],
        formSectionId: user.formSectionId ?? null,
      })),
    tags: tags.filter((tag) => tag.role === role),
  }));
};

export interface ClientFormUserDto {
  role: ClientFormUserRoleValues;
  users: ClientFormUserRequest[];
  tags: ClientFormTag[];
}
